import Vue from 'vue'
import { Icon, Backtop } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import App from './App.vue'
import vueRouter from './router'
import 'normalize.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper)
Vue.use(Icon)
Vue.use(Backtop)

Vue.config.productionTip = false;
Vue.config.devtools = true;

// 环境变量 baseURL
axios.defaults.baseURL = 'https://api.backeytech.com/admin/';
Vue.prototype.$axios = axios;

new Vue({
	router: vueRouter,
	render: h => h(App)
}).$mount('#app')

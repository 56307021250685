<template>
  <div id="app">
	<router-view></router-view>
	<el-backtop></el-backtop>
  </div>
</template>

<script>

export default {
	name: 'App',
	components: {

	},
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: #F5F5F5;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	background-color: #F5F5F5;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
	border-radius: 8px;
	/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
	background-color: #555;
}
</style>

import Vue from 'vue'
import vueRouter from 'vue-router'

Vue.use(vueRouter)


const originalPush = vueRouter.prototype.push
vueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const router = new vueRouter({
	routes: [
		{
			path: '/',
			name: '/',
			component: () => import('@/view/index/index'),
			// component: resolve => require(['@/view/index/index'], resolve),
		}, {
			path: '/projectCase',
			name: 'projectCase',
			component: () => import('@/view/projectCase/index'),
		}, {
			path: '/productService',
			name: 'productService',
			component: () => import('@/view/productService/index'),
		}, {
			path: '/productService2',
			name: 'productService2',
			component: () => import('@/view/productService2/index'),
		}, {
			path: '/productService3',
			name: 'productService3',
			component: () => import('@/view/productService3/index'),
		}, {
			path: '/productService4',
			name: 'productService4',
			component: () => import('@/view/productService4/index'),
		}, {
			path: '/aboutUs',
			name: 'aboutUs',
			component: () => import('@/view/aboutUs/index'),
		}, {
			path: '/contactUs',
			name: 'contactUs',
			component: () => import('@/view/contactUs/index'),
		}, {
			path: '/download',
			name: 'download',
			component: () => import('@/view/download/index'),
		}
	]
})


export default router;